export const environment = {
  production: true,

  appUrl: 'gord-frontend.homologacao.prodemge.gov.br',
  apiUrl: 'https://gord-frontend.homologacao.prodemge.gov.br/*',
  service: 'https://gord.homologacao.prodemge.gov.br/api/v1',
  
  ssc_issuer: 'https://homologacao.ssc.prodemge.gov.br/ssc-idp-frontend/',
  ssc_redirectUri: 'https://gord-frontend.homologacao.prodemge.gov.br',
  ssc_clientId: 'Ki6GAjYbETDE73KVwWtPCYWd-12082019-gord',
  ssc_clientSecret: "FYriReqY17bpSiZPH96A0XAV",
  ssc_scope: 'openid gord:oauth001 email offline_access',
  ssc_discovery: 'https://homologacao.ssc.prodemge.gov.br/ssc-idp-frontend/rest/oidc/discovery',
  authorization_ssc: 'Basic S2k2R0FqWWJFVERFNzNLVndXdFBDWVdkLTEyMDgyMDE5LWdvcmQ6RllyaVJlcVkxN2JwU2laUEg5NkEwWEFW',    
  
};

console.log = function():void{}; 
